<template>
  <div class="password-validation uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
    <div>
      <h5 class="uk-text-bold mb-2">{{ $t('profile.login_password') }}</h5>
      <div class="uk-position-relative">
        <input ref="passwordInput" v-model="localPassword" class="uk-input mb-1"
          :type="showPassword ? 'text' : 'password'" autocomplete="chrome-off" name="'password-val'"
          @mouseup="copyPassword">
        <button type="button" class="uk-position-center-right uk-position-small"
          style="background: none; border: none; cursor: pointer;" @click="togglePasswordVisibility">
          <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
      </div>
      <ol class="bg-white W-100 pt-1 pl-2 mb-1">
        <li v-if="passwordValidation.hasUpperLetter" class="uk-text-small m-0" style="color:grey;">
          <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>
          {{ $t('errors.password_must_have_upper_latter') }}
        </li>
        <li v-if="passwordValidation.hasLowerLetter" class="uk-text-small m-0" style="color:grey;">
          <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>
          {{ $t('errors.password_must_have_lower_latter') }}
        </li>
        <li v-if="passwordValidation.hasNumber" class="uk-text-small m-0" style="color:grey;">
          <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>
          {{ $t('errors.password_must_have_number') }}
        </li>
        <li v-if="passwordValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:grey;">
          <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>
          {{ $t('errors.password_must_more_than_7') }}
        </li>
        <li v-if="!passwordValidation.hasUpperLetter" class="uk-text-small m-0" style="color:red;">
          <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>
          {{ $t('errors.password_must_have_upper_latter') }}
        </li>
        <li v-if="!passwordValidation.hasLowerLetter" class="uk-text-small m-0" style="color:red;">
          <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>
          {{ $t('errors.password_must_have_lower_latter') }}
        </li>
        <li v-if="!passwordValidation.hasNumber" class="uk-text-small m-0" style="color:red;">
          <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>
          {{ $t('errors.password_must_have_number') }}
        </li>
        <li v-if="!passwordValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:red;">
          <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>
          {{ $t('errors.password_must_more_than_7') }}
        </li>
      </ol>
    </div>

    <div>
      <h5 class="uk-text-bold mb-2">{{ $t('profile.login_password_confirm') }}</h5>
      <div class="uk-position-relative">
        <input v-model="localPasswordConfirm" class="uk-input" :type="showPassword ? 'text' : 'password'"
          name="'password-val-confirm'">
        <button type="button" class="uk-position-center-right uk-position-small"
          style="background: none; border: none; cursor: pointer;" @click="togglePasswordVisibility">
          <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
      </div>
      <li v-if="!isMatched" class="uk-text-small m-0" style="color:red;">
        <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>
        Şifreler Eşleşmiyor
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordFields",
  props: {
    password: {
      type: String,
      default: null
    },
    passwordConfirmation: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      localPassword: this.password,
      localPasswordConfirm: this.passwordConfirmation,
      showPassword: false,
    };
  },
  computed: {
    isMatched() {
      if (this.localPasswordConfirm === this.localPassword) {
        return true
      }
      return !this.localPasswordConfirm;
    },

    passwordValidation() {
      let validation = {
        'hasLowerLetter': false,
        'hasUpperLetter': false,
        'hasNumber': false,
        'hasAtLeast8Letters': false,
      };

      if (this.localPassword) {
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;

        if (this.localPassword.match(lowerCaseLetters)) {
          validation.hasLowerLetter = true;
        }
        if (this.localPassword.match(upperCaseLetters)) {
          validation.hasUpperLetter = true;
        }
        if (this.localPassword.match(numbers)) {
          validation.hasNumber = true;
        }
        if (this.localPassword.length > 7) {
          validation.hasAtLeast8Letters = true;
        }
      }

      return validation;
    }
  },
  watch: {
    localPassword(newVal) {
      this.$emit('update:password', newVal);
    },
    localPasswordConfirm(newVal) {
      this.$emit('update:passwordConfirmation', newVal);
    },
    password(newVal) {
      this.localPassword = newVal;
    },
    passwordConfirmation(newVal) {
      this.localPasswordConfirm = newVal;
    }
  },
  methods: {
    async copyPassword() {
      try {
        if (this.localPassword) {
          await navigator.clipboard.writeText(this.localPassword); // Şifreyi kopyala
          this.copied = true;
          setTimeout(() => (this.copied = false), 2000); // 2 saniye sonra mesaj kaybolur
        }
      } catch (err) {
        console.error("Kopyalama başarısız:", err);
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>

<style scoped>
.password-validation input {
  height: 44px !important;
  user-select: text !important;
}
</style>